window.jQuery = window.$ = require('jquery');

require('bootstrap');
require('jquery-colorbox');
require('slick-carousel');

$(document).ready(()=>{
	function toggleNav(){
	  var chkbox = document.getElementById("mobile-toggle");
	  chkbox.checked = !chkbox.checked;
	  //console.log('rest');
	}
	var nav = document.getElementById('main-nav');
	nav.addEventListener('click', toggleNav);
});

$('a.display-image').colorbox({maxWidth:'95%',maxHeight:'95%'});

import AOS from 'aos';
AOS.init();

$('.crafty-block-slideshow').slick({
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500
});

$('.testimonial-list').slick({
    dots: true,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true
          }
        }
      ]
});
